import React from "react"
import "oneauxilia-react/dist/index.css"
import Router from "./routes"
import "./App.css"

export default function App() {
  console.log("Versipon 03/10")
  return (
    <div>
      <Router />
    </div>
  )
}
